<template>
  <div class="container">
    <div class="logo">
      MATKA<span>GURU</span>.ONLINE<small>Raise your monery</small>
    </div>
  </div>
  <div class="page">
    <section>
      <div class="container">
        <NoticeHeader :notifiers="notifiers"></NoticeHeader>
        <LiveResults :liveGames="liveGames"></LiveResults>
        <DailyResults :dailyGames="dailyGames"></DailyResults>
        <!-- <ContactCard></ContactCard> -->
        <FatafatResults :tableTimes="TableTimes"></FatafatResults>
        <GuessingTable :guessingTables="guessingTables"></GuessingTable>
      </div>
    </section>
    <section class="footer">
      <div class="DISCLAIMER">
        <h3>DISCLAIMER</h3>

        <span v-if="notifiers">
         {{notifiers.footer}}
        </span>
      </div>

      <div class="powerd">POWERED BY TEZMATKA.in</div>
    </section>
  </div>
</template>

<script>
import NoticeHeader from "../components/NoticeHeader.vue";
import LiveResults from "../components/LiveResults.vue";
import DailyResults from "../components/DailyResults.vue";
// import ContactCard from "../components/ContactCard.vue";
import FatafatResults from "../components/FatafatResults.vue";
import GuessingTable from "../components/GuessingTable.vue";

export default {
  created() {
    this.getHomeData();
  },
  components: {
    NoticeHeader,
    LiveResults,
    DailyResults,
    // ContactCard,
    FatafatResults,
    GuessingTable
  },
  data() {
    return {
      dailyGames: Array(),
      TableTimes: Array(),
      guessingTables:Array(),
      liveGames: Array(),
      notifiers: Array(),
    };
  },
  methods: {
    getHomeData() {
      this.$axios("home")
        .then((res) => {
          console.log(res.data);
          this.dailyGames = res.data.daily_games;
          this.TableTimes = res.data.table_times;
          this.liveGames = res.data.live_games;
          this.guessingTables = res.data.guessing_tables;
          this.notifiers = res.data.notifiers;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <section class="welcome">
    <h1>
      !! Welcome Friends in the <br />Matka Guru Result <br />Get Live Matka
      Guru Update Here !!
    </h1>
    <a id="login" href="https://ematka.in/login">Login</a>
  </section>
  <section class="bordered-section">
    <h3 class="mb20">Matka Guru Result | Matka Guru Live Result</h3>
   <div v-html="notifiers.marquee"></div>
  </section>
  <section class="welcome">
    <h1>
      <span>Notice Board</span>
      <hr />
      <br />
      <div class="notice-content">
        <span class="red-text">
          <span class="blue-text">Matka Guru</span><br />
        </span>
        <div v-html="notifiers.notification"></div>
      </div>
      <br />
    </h1>
  </section>
</template>

<script>
export default {
   props: {
    notifiers: Array(),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

.bordered-section {
  border: 1px solid;
}

.italic-text {
  font-style: italic;
}

.red-text {
  color: red;
  font-size: 14px;
}

.blue-text {
  color: blue;
  font-size: 14px;
}

.notice-content {
  margin-bottom: 10px;
}

.purple-text {
  color: purple;
  font-size: 14px;
}

.big-text {
  font-size: 16px;
}

#login{
  position: relative;
    display: inline-block;
    color: #fff;
    padding: 6px 38px;
    background: linear-gradient(90deg, #6616d0, #ac34e7);
    border-radius: 45px;
    margin: 10px;
    font-size: 15x;
    font-weight: 400;
    text-decoration: none;
    box-shadow: 3px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
</style>

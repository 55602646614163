import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DailyJodiPanelView from "../views/DailyJodiPanelView.vue"
import DailyPanelView from "../views/DailyPanelView.vue"
import FatafatPanelView from "../views/FatafatPanelView.vue";
const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/daily_panel/:id',
        name: 'dailyPanel',
        component: DailyPanelView
    },
    {
        path: '/jodi_panel/:id',
        name: 'jodiPanel',
        component: DailyJodiPanelView
    },
    {
        path: '/fatafat_panel/:id',
        name: 'fatafatPanel',
        component: FatafatPanelView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
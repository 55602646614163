<template>
  <section class="resultsss full" style="">
    <h2
      class="title"
      style="
        font-size: 25px;
        border-bottom: 1px solid;
        border-radius: 0;
        background: unset;
      "
    >
      DAILY RESULTS
    </h2>
    <div class="flex" v-for="game in dailyGames" :key="game">
      <!-- <div class="item">
        <div>
          
        </div>
        <div>
          <span class="time">{{ game.time }}</span>
        </div>
        <div class="d-flex justify-content-between">
            
            
        </div>
        <div class="result">{{ game.result }}</div>
      </div> -->
      <div class="item">
        <div>
          <h4>{{ game.name }}</h4>
        </div>
        <div class="result">{{ game.result }}</div>
        <div class="flex-item">
          <span class="btns"
            ><button class="btn btn-refresh left">
              <router-link :to="{ name: 'jodiPanel', params: { id: game.id } }"
                >Jodi</router-link
              >
            </button>
            <button class="btn btn-refresh right">
              <router-link :to="{ name: 'dailyPanel', params: { id: game.id } }"
                >panel</router-link
              >
            </button>
          </span>

          <div v-html="this.extractTimesWithTags(game.time)"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    dailyGames: Array(),
  },
  methods:{
    extractTimesWithTags(input) {
  const [startTime, endTime] = input
    .match(/\d{1,2}:\d{2} [APMapm]{2}/g)
    .map(timeString => `<i class="material-icons-outlined">schedule</i>${timeString}`);

  return `<span class="time">${startTime}</span>  <span class="time">${endTime}</span>`;
}
  }
};
</script>
<style scoped>
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}

.panel-btn {
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  );
  border-radius: 12px;
  border: white 0.01rem solid;
  padding: 5px;
  font-size: 12px;
}

.jodi-btn {
  color: #fff;
  background-image: linear-gradient(
    to right top,
    #3a2066,
    #422575,
    #4a2983,
    #532e93,
    #5b33a2,
    #5b33a2,
    #5b33a1,
    #5b33a1,
    #522e91,
    #4a2a82,
    #412573,
    #392164
  );
  border-radius: 12px;
  border: white 0.01rem solid;
  padding: 5px;
  font-size: 12px;
}
</style>
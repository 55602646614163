<template>
  <!-- <header>
    <img id="logo" src="./assets/logo.png" alt="" />
  </header> -->
  <router-view />
  <div class="footer_btn">
    <a class="refresh-btn" onclick="window.location.reload()"
      ><i class="material-icons-outlined">refresh</i>
    </a>
  </div>
</template>
  
<style lang="scss">
</style>

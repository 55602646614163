<template>
<div>
    <div v-if="tableTime">
       <h2 class="text-center text-white resultHeader py-2">{{tableTime.game_name}}</h2>
    <table border="1" width="90%" class="table_panel">
        <thead class="resultText table_header">
            <td>date</td>
            <td v-if="tableTime.one">{{ tableTime.one }}</td>
            <td v-if="tableTime.two">{{ tableTime.two }}</td>
            <td v-if="tableTime.three">{{ tableTime.three }}</td>
            <td v-if="tableTime.four">{{ tableTime.four }}</td>
            <td v-if="tableTime.five">{{ tableTime.five }}</td>
            <td v-if="tableTime.six">{{ tableTime.six }}</td>
            <td v-if="tableTime.seven">{{ tableTime.seven }}</td>
            <td v-if="tableTime.eight">{{ tableTime.eight }}</td>
            <td v-if="tableTime.nine">{{ tableTime.nine }}</td>
            <td v-if="tableTime.ten">{{ tableTime.ten }}</td>
            <td v-if="tableTime.eleven">{{ tableTime.eleven }}</td>
            <td v-if="tableTime.twelve">{{ tableTime.twelve }}</td>
            <td v-if="tableTime.thirteen">{{ tableTime.thirteen }}</td>
            <td v-if="tableTime.fourteen">{{ tableTime.fourteen }}</td>

        </thead>
        <tbody>
            <tr class="resultText" v-for="tableGame in tableGames" :key="tableGame.id">
                <td>{{ formatDate(tableGame.created_at) }}</td>
            <td v-if="tableTime.one">{{ tableGame.one }}</td>
            <td v-if="tableTime.two">{{ tableGame.two }}</td>
            <td v-if="tableTime.three">{{ tableGame.three }}</td>
            <td v-if="tableTime.four">{{ tableGame.four }}</td>
            <td v-if="tableTime.five">{{ tableGame.five }}</td>
            <td v-if="tableTime.six">{{ tableGame.six }}</td>
            <td v-if="tableTime.seven">{{ tableGame.seven }}</td>
            <td v-if="tableTime.eight">{{ tableGame.eight }}</td>
            <td v-if="tableTime.nine">{{ tableGame.nine }}</td>
            <td v-if="tableTime.ten">{{ tableGame.ten }}</td>
            <td v-if="tableTime.eleven">{{ tableGame.eleven }}</td>
            <td v-if="tableTime.twelve">{{ tableGame.twelve }}</td>
            <td v-if="tableTime.thirteen">{{ tableGame.thirteen }}</td>
            <td v-if="tableTime.fourteen">{{ tableGame.fourteen }}</td>
            </tr>
        </tbody>
    </table>
    </div>

</div>
</template>

<script>

export default {
 data(){
     return{
       tableTime:null,
       tableGames:null
     }
 },
 created(){
     this.getTableGamePanel(this.$route.params.id)
 },
 methods:{
     getTableGamePanel(id)
     {
         this.$axios.get('table_panel/'+id)
         .then(function(response){
          this.tableTime= response.data.table_time
          this.tableGames = response.data.table_games
         }.bind(this)).catch(function(error){
            alert(error.response.data.message);
          if(error.response.status == 401){
            localStorage.clear();
            window.location.reload()
          }
         })
     },
     formatDate(date)
    {
        return new Date(date).toLocaleDateString("hi-IN")
    },
 }
}
</script>

<style scoped>
  .resultText > td{
      font-size: 2.3vw;
      
  }
  tbody .resultText > td{
      color:rgb(255, 255, 255)
  }
  .table_panel {
  text-align: center;
  margin: 10px auto;
  color: white;
  font-size: 20px;
  border: solid 2px white;
  border-radius: 4px;
}
.table_header{
    background: red;
}
hr{
    margin:0
}

</style>

<template>
  <section class="result">
    <div class="header-title">
      <h2 class="title">Live result</h2>
      <span style="">Sabse Tezz Live Result Yahi Milega</span>
    </div>
    <div
      class="flex"
      style="padding: 20px 0"
      v-for="game in liveGames"
      :key="game"
    >
      <div class="item">
        <div>
          <h4>{{ game.name }}</h4>
        </div>
        <div class="result">{{ game.result }}</div>
        <button class="btn btn-refresh" onclick="window.location.reload()">
          Reload
        </button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    liveGames: Array(),
  },
};
</script>
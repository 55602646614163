<template>
  <section style="background: #fff; margin-top: 10px;">
    <h2 style="color: black; font-weight: bold">Fatafat Games</h2>
  </section>
  <section class="table_result">
    <div class="table_wrapper" v-for="tableTime in tableTimes" :key="tableTime">
      <h2 style="background: unset; font-size: 30px">
        {{ tableTime.game_name }}
      </h2>
      <router-link class="panel_btn"
     :to="{name:'fatafatPanel',params:{id:tableTime.id}}" >Panel Chart</router-link>
      <table class="table">
        <thead>
            <tr>
            <th>Time</th>
            <th>Result</th>
            <th>Time</th>
            <th>Result</th>
          </tr>
        </thead>
          <tbody>
            <tr v-for="(times, index) in tableTime.times[0]" :key="index">
              <td>{{ times }}</td>
              <td>{{tableTime.currentGame[0][index]}}</td>
              <td>{{ tableTime.times[1][index] }}</td>
              <td>{{tableTime.currentGame[1][index]}}</td>
          </tr>
          </tbody>
      
      </table>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tableTimes: Array(),
  },
};
</script>

<style>
</style>
<template>
  <div>
    <h3 class="text-center py-2 guessingHeader">{{ date }} Guessing</h3>
    <div
      v-for="guessingTable in guessingTables"
      :key="guessingTable.id"
      class="text-center"
    >
      <h3 class="text-center guessing-table-header py-2">
        {{ guessingTable.name }}
      </h3>
      <table width="90%" class="center border border-primary">
        <tbody>
          <td
            v-for="panel in guessingTable.guessing_table_panels"
            :key="panel.id"
            class="text-center border border-warning"
          >
            <table class="center">
              <tbody>
                <tr>
                  <td>{{ panel.patti_1 }}</td>
                  <td></td>
                  <td>{{ panel.patti_2 }}</td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <span class="ank-text">{{ panel.figure }}</span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>{{ panel.patti_3 }}</td>
                  <td></td>
                  <td>{{ panel.patti_4 }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().toLocaleDateString("en-IN"),
    };
  },
  props: {
    guessingTables: Array(),
  },
};
</script>

<style scoped>
.guessing-table-header {
  font-weight: bold;
  
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.center > tbody > tr >td{
  font-weight: bolder;
  font-size: 9px;
}
.ank-text{
  color: #ffd800;
  font-weight: bold;
  font-size: 12px;
}
.guessingHeader {
  color: white;
  margin-top: 0px;
  margin-bottom: 10px;
  background-color: #002651;
  font-weight: bolder;
  padding-top: 6px;
}
.text-center{
    text-align: center !important;
}
</style>
